import { render, staticRenderFns } from "./BorrowForm.vue?vue&type=template&id=661e5c97&scoped=true&"
import script from "./BorrowForm.vue?vue&type=script&lang=js&"
export * from "./BorrowForm.vue?vue&type=script&lang=js&"
import style0 from "./BorrowForm.vue?vue&type=style&index=0&id=661e5c97&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661e5c97",
  null
  
)

export default component.exports